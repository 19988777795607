import React from "react";
import styled from "@emotion/styled";
import Loader from "../../utils/Loader";

import { MAP_CLOSE_URL } from "../../s3-resources/paths";
import { MapElements } from "../Map";
import { RoomClickbox } from "../data/data";
import { zoomIdentity } from "d3";
import { getRoomIdFromSkolId } from "../data/generate-data";
import {
  SkolAttribute,
  fetchMapSkolDisplayDataById,
  getMapSkolImageDataByIdUrl,
  SkolDisplayData,
  getCardBackgroundUrlByName,
  getSkolRarityUrlById,
} from "../../s3-resources/paths";

import raritydata from "../data/rarity-data.json";

interface StyledCardProps {
  black?: boolean;
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  ${(props: StyledCardProps) => (props.black ? `color: black;` : ``)}
`;

const StyledCardContent = styled.div`
  position: relative;
  width: 352px;
  height: 512px;
  border-radius: 20px;

  pointer-events: auto;

  @media (max-width: 999px) {
    width: 256px;
    height: 384px;
  }
`;

export const CardBackgroundColor = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #594a4a;
  border-radius: 16px;
`;

export const CardBackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: blur(5px);
  opacity: 0.9;
  border-radius: 16px;
  border: none;
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const CardHeader = styled.div`
  width: calc(100% - 16px);
  padding: 0 8px;
  height: 50px;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 999px) {
    height: 40px;
  }
`;

const CardName = styled.div`
  position: relative;
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  text-align: left;

  margin: 10px 0;

  color: #ffffff;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

  @media (max-width: 999px) {
    font-size: 15px;
    line-height: 18px;
  }
`;

const RarityIcon = styled.img`
  position: relative;
  width: 32px;
  height: 32px;
  margin-top: 2px;

  @media (max-width: 999px) {
    width: 26px;
    height: 26px;
  }
`;

const CloseIcon = styled(RarityIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-top: 0px;

  @media (max-width: 999px) {
    width: 18px;
    height: 18px;
  }
`;

const CloseIconCircle = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: transparent;
  display: grid;
  place-items: center;

  @media (max-width: 999px) {
    width: 30px;
    height: 30px;
    border-radius: 24px;
  }
`;

const Image = styled.img`
  position: relative;
  width: 288px;
  height: 288px;

  background: #2c2c2c;
  border: 2px solid #59595988;
  border-radius: 20px;

  @media (max-width: 999px) {
    width: 192px;
    height: 192px;
  }
`;

const Attributes = styled.div`
  margin-top: 20px;
  width: 256px;
  height: 160px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (max-width: 999px) {
    width: 190px;
    height: 120px;
  }
`;

const Attribute = styled.div`
  width: 128px;
  height: 34px;
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 14px;
  line-height: 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: 0;

  @media (max-width: 999px) {
    width: 96px;
    height: 28px;
    font-size: 10px;
    line-height: 16px;
  }
`;

const AttributeLong = styled(Attribute)`
  width: 192px;
  @media (max-width: 999px) {
    width: 128px;
  }
`;

const TypeText = styled.div`
  margin-right: 8px;
`;

const ValueText = styled.div``;

type CardProps = {
  skolId: number;
  mapElements: MapElements;
  clickbox: RoomClickbox;
  clearSkol: () => void;
  width: number;
  height: number;
};

const Card = (props: CardProps): JSX.Element => {
  const { skolId, mapElements, clickbox, clearSkol, width, height } = props;

  const backgroundName = getRoomIdFromSkolId(skolId);
  const roomBackgroundUrl = getCardBackgroundUrlByName(backgroundName);

  const blackText = backgroundName === "soft" || backgroundName === "sunset";

  const [skolDisplayData, setSkolDisplayData] = React.useState<
    SkolDisplayData | undefined
  >(undefined);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [skolRarityUrl, setSkolRarityUrl] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    getSkolDisplayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skolId]);

  const getSkolDisplayData = async () => {
    setLoading(true);
    await fetchMapSkolDisplayDataById(skolId)
      .then((res: SkolDisplayData | undefined) => {
        setSkolDisplayData(res);

        if (res) {
          const skolIdInt: number = parseInt(`${skolId}`);
          const rarity: string = raritydata[skolIdInt];
          const newSkolRarityUrl = getSkolRarityUrlById(rarity);
          setSkolRarityUrl(newSkolRarityUrl);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const imageUrl = getMapSkolImageDataByIdUrl(skolId);

  const renderAttributes = skolDisplayData ? (
    <Attributes>
      {skolDisplayData.attributes.map(
        (attribute: SkolAttribute, index: number) => {
          const AttributeStyled = index > 3 ? AttributeLong : Attribute;
          return (
            <AttributeStyled key={attribute.trait_type}>
              <TypeText>{`${attribute.trait_type}: `}</TypeText>
              <ValueText>{attribute.value}</ValueText>
            </AttributeStyled>
          );
        }
      )}
    </Attributes>
  ) : null;

  const handleClose = () => {
    mapElements.svg
      .transition()
      .duration(1000)
      .call(
        mapElements.zoomd3.transform,
        zoomIdentity
          .translate(width / 2, height / 2)
          .scale(clickbox.translate.scale)
          .translate(clickbox.translate.x, clickbox.translate.y)
      );

    // Sleep one second while transition occurs
    setTimeout(() => {
      clearSkol();
    }, 1000);
  };

  return (
    <StyledCard black={blackText}>
      <StyledCardContent>
        <CardBackgroundColor />
        <CardBackgroundImage
          id={`card-background-image-${skolId}`}
          src={roomBackgroundUrl}
          alt={`Card Background Image for Sk0L ${skolId}`}
        />
        {!loading && skolDisplayData ? (
          <CardContent>
            <CardHeader>
              <CloseIconCircle onClick={() => handleClose()}>
                <CloseIcon
                  id={`close`}
                  src={MAP_CLOSE_URL}
                  alt={`Close Button`}
                />
              </CloseIconCircle>
              <CardName>{skolDisplayData.name}</CardName>
              <RarityIcon
                id={`rarity-${skolId}`}
                src={skolRarityUrl}
                alt={`Rarity for Sk0L ${skolId}`}
              />
            </CardHeader>
            <Image src={imageUrl} />
            {renderAttributes}
          </CardContent>
        ) : (
          <Loader />
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default Card;
