import React from "react";
import styled from "@emotion/styled";

import { MAX_CONTENT_WIDTH } from "../utils/universals";
import useWindowDimensions from "./utils/useWindowDimensions";
import Map from "./Map";

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const MapContent = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  max-width: ${MAX_CONTENT_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const MapInline = (): JSX.Element => {
  const { width, height } = useWindowDimensions();

  const skolUrl: string = "";

  const mapWidth = Math.min(width * 0.85, height / 1.2, 1200);
  const mapHeight = 1.1 * mapWidth;

  const clearSkolUrl = () => {};

  const updateSkolUrl = (id: number) => {};

  const renderCircles = (
    <MapContent>
      <Map
        width={mapWidth}
        height={mapHeight}
        skolUrl={skolUrl}
        updateSkolUrl={updateSkolUrl}
        clearSkolUrl={clearSkolUrl}
      />
    </MapContent>
  );

  return <Container>{renderCircles}</Container>;
};

export default MapInline;
