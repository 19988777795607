import { select, zoom, zoomIdentity, interpolate, pointer } from "d3";

import { MAP_EXIT_URL, MAP_MUSEUM_URL } from "../../s3-resources/paths";

const renderMap = (
  width,
  height,
  defaultTranslate,
  translate,
  data,
  visibleData,
  roomClickboxes,
  roomViews,
  roomId,
  updateRoom,
  clearRoom,
  skolId,
  updateSkol,
  clearSkol
) => {
  console.log({ data });

  const frame = select("#frame");

  frame.selectAll("*").remove();

  const still = frame.append("svg").attr("height", height).attr("width", width);

  const svg = still.append("svg").attr("height", height).attr("width", width);

  svg.append("image").attr("xlink:href", MAP_MUSEUM_URL).attr("width", width);

  svg
    .selectAll("svg")
    .data(roomViews)
    .enter()
    .append("image")
    .attr("xlink:href", (room) => room.url)
    .attr("width", (room) => room.width)
    .attr("style", (room) => `width:${room.width}px;`)
    .attr("x", (room) => room.x)
    .attr("y", (room) => room.y)
    .attr("opacity", (room) => (room.id === roomId ? 0 : 1));

  const skolHighlightFocus = svg
    .append("rect")
    .attr("width", 40)
    .attr("height", 40)
    .attr("fill", "#FFFFFF00")
    .attr("rx", 30)
    .attr("x", 0)
    .attr("y", 0);

  const skolHighlight = svg
    .append("rect")
    .attr("width", 40)
    .attr("height", 40)
    .attr("fill", "#FFFFFF00")
    .attr("rx", 30)
    .attr("x", 0)
    .attr("y", 0);

  svg
    .selectAll("svg")
    .data(roomClickboxes)
    .enter()
    .append("rect")
    .attr("width", (room) => room.width)
    .attr("height", (room) => room.height)
    .attr("fill", "#FFFFFF00")
    .attr("x", (room) => room.x)
    .attr("y", (room) => room.y)
    .attr("rx", "4px")
    .attr("cursor", (room) => (roomId !== room.id ? "pointer" : "normal"))
    .on("click", (event, room) => {
      if (roomId === room.id) {
        return;
      }
      event.stopPropagation();

      svg
        .transition()
        .duration(1000)
        .call(
          zoomd3.transform,
          zoomIdentity
            .translate(width / 2, height / 2)
            .scale(room.translate.scale)
            .translate(room.translate.x, room.translate.y),
          pointer(event)
        )
        .on("end", () => {
          updateRoom(room.id);
        });
    });

  svg
    .selectAll("svg")
    .data(visibleData)
    .enter()
    .append("rect")
    .attr("width", (skol) => skol.radius)
    .attr("height", (skol) => skol.radius)
    .attr("fill", (skol) => skol.color)
    .attr("rx", (skol) => skol.radius)
    .attr("x", (skol) => skol.x)
    .attr("y", (skol) => skol.y)
    .attr("cursor", "pointer")
    .on("mouseover", (event, skol) => {
      const multi = 3 / 2;
      skolHighlightFocus
        .attr("fill", "#7d177355")
        .attr("x", skol.x - skol.radius / 4)
        .attr("y", skol.y - skol.radius / 4)
        .attr("width", skol.radius * multi)
        .attr("height", skol.radius * multi)
        .attr("rx", skol.radius * multi);
    })
    .on("mouseout", () => {
      skolHighlightFocus.attr("fill", "#7d177300");
    })
    .on("click", (event, skol) => {
      event.stopPropagation();
      svg
        .transition()
        .duration(1000)
        .call(
          zoomd3.transform,
          zoomIdentity
            .translate(width / 2, height / 2)
            .scale(6)
            .translate(
              -(skol.x + skol.radius / 2),
              -(skol.y + skol.radius / 2)
            ),
          pointer(event)
        )
        .on("end", () => updateSkol(skol.id));
      const multi = 3 / 2;
      skolHighlight
        .attr("fill", "#7d177399")
        .attr("x", skol.x - skol.radius / 4)
        .attr("y", skol.y - skol.radius / 4)
        .attr("width", skol.radius * multi)
        .attr("height", skol.radius * multi)
        .attr("rx", skol.radius * multi);
    });

  if (!skolId) {
    const circle = still
      .append("rect")
      .attr("width", 48)
      .attr("height", 48)
      .attr("fill", "#7d177399")
      .attr("rx", 24)
      .attr("x", 10)
      .attr("y", 10)
      .attr("cursor", "pointer");

    const exit = still
      .append("image")
      .attr("xlink:href", MAP_EXIT_URL)
      .attr("width", 32)
      .attr("x", 18)
      .attr("y", 18)
      .attr("cursor", "pointer");

    const exitToMap = (event) => {
      event.stopPropagation();
      svg
        .transition()
        .duration(1000)
        .call(
          zoomd3.transform,
          zoomIdentity
            .translate(width / 2, height / 2)
            .scale(defaultTranslate.scale)
            .translate(defaultTranslate.x, defaultTranslate.y),
          pointer(event)
        )
        .on("end", () => {
          clearSkol();
          clearRoom();
        });
    };

    circle.on("click", (event) => exitToMap(event));
    exit.on("click", (event) => exitToMap(event));
  }

  const zoomed = ({ transform }) => {
    svg.selectAll("rect").attr("transform", transform);
    svg.selectAll("image").attr("transform", transform);
  };

  const zoomd3 = zoom()
    .interpolate(interpolate)
    .scaleExtent([0.5, 20])
    .on("zoom", zoomed);

  svg.call(zoomd3).on("dblclick.zoom", null);

  svg
    .transition()
    .duration(0)
    .call(
      zoomd3.transform,
      zoomIdentity
        .translate(width / 2, height / 2)
        .scale(translate.scale)
        .translate(translate.x, translate.y)
    );

  return { svg, zoomd3 };
};

export default renderMap;
