import React from "react";

import styled from "@emotion/styled";
import { MAX_CONTENT_WIDTH } from "../utils/universals";

const FooterSection = styled.div`
  position: relative;
  background-color: transparent;
  width: 100%;
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const FooterContent = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GreyFooterText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #646e73;
  margin: 0.5rem 2rem 0.5rem 2rem;
  max-width: 60rem;
  @media (max-width: 599px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const Footer = (): JSX.Element => {
  return (
    <FooterSection>
      <FooterContent>
        <GreyFooterText>{`© Sp00ky Sk0Ls. 2021. All rights reserved`}</GreyFooterText>
        <GreyFooterText>{`When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.`}</GreyFooterText>
      </FooterContent>
    </FooterSection>
  );
};

export default Footer;
