import React from "react";
import styled from "@emotion/styled";
import Card from "./Card";
import { MapElements } from "../Map";
import { RoomClickbox } from "../data/data";

const Modal = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 15px);
  padding-top: 15px;
  display: grid;
  place-items: center;
  pointer-events: none;
`;

type CardModalProps = {
  skolId: number;
  mapElements: MapElements;
  clickbox: RoomClickbox;
  clearSkol: () => void;
  width: number;
  height: number;
};

const CardModal = (props: CardModalProps): JSX.Element => {
  const { mapElements, skolId, clickbox, clearSkol, width, height } = props;
  return (
    <Modal>
      <Card
        skolId={skolId}
        mapElements={mapElements}
        clickbox={clickbox}
        clearSkol={clearSkol}
        width={width}
        height={height}
      />
    </Modal>
  );
};

export default CardModal;
