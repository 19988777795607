import styled from "@emotion/styled";

import Roadmap from "./Roadmap";
import FaqSection from "./FaqSection";
import { useMediaQuery } from "@mui/material";
import { MAX_CONTENT_WIDTH } from "../utils/universals";
import {
  getHomepageImageByName,
  getMapSkolImageDataByIdUrl,
  getVideoByName,
} from "../s3-resources/paths";
import MapInline from "../map/MapInline";
import Card from "./Card";
import {
  DISCORD_URL,
  MUSEUM_URL,
  NavButton,
  NavButtonBlue,
} from "../nav/utils";
import NFTJetLogo from "../assets/nftjet.png";

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.div`
  position: relative;
  width: 100%;
  height: 8rem;
  background: transparent;
  @media (max-width: 799px) {
    height: 4rem;
  }
`;

const HalfSpacer = styled.div`
  position: relative;
  width: 100%;
  height: 4rem;
  background: transparent;
  @media (max-width: 799px) {
    height: 2rem;
  }
`;

const HalfSpacerMobile = styled(HalfSpacer)`
  @media (min-width: 800px) {
    display: none;
  }
`;

export const BackgroundImage = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

const BackgroundColor = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    359.94deg,
    rgba(25, 118, 210, 0.61) 0.05%,
    rgba(0, 0, 0, 0.91) 0.06%,
    rgba(0, 0, 0, 0.39) 99.95%
  );
`;

const HeroSectionContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeroSectionText = styled.div`
  width: 60%;
  margin: 8rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  @media (max-width: 1199px) {
    width: 40%;
    margin: 10rem 0% 10rem 10%;
  }
  @media (max-width: 799px) {
    width: 50%;
    margin: 8rem 0% 8rem 10%;
  }
  @media (max-width: 599px) {
    margin: 3rem 10% 3rem 10%;
  }
`;

const HeroSectionTitle = styled.h1`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 56px;
  margin: 0 0 2rem 0;
  @media (max-width: 1199px) {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 1rem 0;
  }
  @media (max-width: 799px) {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 0.5rem 0;
  }
  @media (max-width: 599px) {
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 0.5rem 0;
  }
  @media (max-width: 399px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const HeroSectionTitleTop = styled(HeroSectionTitle)`
  margin: 0 0 0 0;

  @media (max-width: 1199px) {
    margin: 0 0 0 0;
  }
  @media (max-width: 599px) {
    margin: 0 0 0 0;
  }
  @media (max-width: 399px) {
    margin: 0 0 0 0;
  }
`;

const HeroSectionSubtitle = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 2rem 0;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 1.5rem 0;
  }
  @media (max-width: 799px) {
    margin: 0 0 1rem 0;
  }
  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (max-width: 399px) {
    font-size: 11px;
    line-height: 13px;
  }
`;

const SkullVideo = styled.video`
  width: 36%;
  margin: 10rem 2rem;
  border-radius: 3rem;
  box-shadow: 4px 6px 4px #00000088;
  position: relative;
  @media (max-width: 1199px) {
    margin: 8rem 4.8rem;
  }
  @media (max-width: 799px) {
    margin: 4rem 10% 4rem 10%;
    border-radius: 2rem;
  }
  @media (max-width: 599px) {
    margin: 3rem 10% 3rem 0%;
    border-radius: 1rem;
  }
`;

const WhatSection = styled.div`
  position: relative;
  background-color: transparent;
  padding: 3rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1199px) {
    padding: 4rem 6rem;
  }
  @media (max-width: 799px) {
    padding: 2rem 4rem;
  }
  @media (max-width: 599px) {
    padding: 2rem 2rem;
  }
`;

const WhatSectionContent = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const SectionTitle = styled.h1`
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  margin: 0 0 2rem 0;
  @media (max-width: 1199px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 1rem 0;
  }
`;

const WhatSectionSubtitle = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 2rem 0;
  @media (max-width: 799px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 599px) {
    margin: 0 0 1rem 0;
  }
`;

const ArtGrid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-gap: 50px 50px;
  margin-top: 20px;
  @media (max-width: 799px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const SkolArt = styled.img`
  width: 16rem;
  height: 16rem;

  @media (max-width: 999px) {
    width: 12rem;
    height: 12rem;
  }
`;

const MintSectionContent = styled(WhatSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  max-width: 900px;

  @media (max-width: 799px) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
`;

const MintItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 354px;
`;

const MintItemTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 34px;

  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
`;

const MintItemSvg = styled.img`
  width: 352px;
  height: 512px;
  margin-bottom: 32px;

  @media (max-width: 999px) {
    width: 256px;
    height: 384px;
  }
`;

const MintItemText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  max-width: 80%;

  @media (max-width: 799px) {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 54px;
  }
`;

const MintItemText2 = styled(MintItemText)`
  margin-bottom: 0px;
  @media (max-width: 799px) {
    margin-bottom: 0px;
  }
`;

const FeaturedSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FeaturedBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;
const FeaturedImg = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 20px;
`;

const LinksSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  @media (max-width: 999px) {
    flex-direction: column;
    align-items: start;
  }
`;

interface SpaceProps {
  mobile?: boolean;
}

const Space = styled.div`
width: 2rem;
height: 1rem;
background-color; transparent;
@media (min-width: 800px) {
  display: ${(props: SpaceProps) => (props.mobile ? "none" : "")};
}
@media (max-width: 799px) {
  display: ${(props: SpaceProps) => (!props.mobile ? "none" : "")};
}
`;

const selectedSkols: number[] = [3880, 4865, 2499, 812, 4276, 3539];

const GLASS_SKOL_URL: string = getVideoByName(`glass-skol.mp4`);

const HomePage = (): JSX.Element => {
  const lessThan800 = useMediaQuery("(max-width:799px)");

  const renderHeroSection: JSX.Element = (
    <HeroSection id="about">
      <BackgroundImage
        id="background-image"
        src={getHomepageImageByName("background.png")}
        alt="universe"
      />
      <BackgroundColor id="background-color" />
      <HeroSectionContent>
        <HeroSectionText>
          <HeroSectionTitleTop>{`Sp00ky Sk0Ls`}</HeroSectionTitleTop>
          <HeroSectionTitle>{`3D Metaverse`}</HeroSectionTitle>
          <HeroSectionSubtitle>
            {lessThan800
              ? `Our collection of 3D NFT art 
              is the key to your digital land in the Sp00kyVerse Museum.`
              : `We are the Sp00ky Sk0Ls. 
              A metaverse project where our collection of 1800 3D NFT art 
              is the key to your digital land in the Sp00kyVerse Museum.`}
          </HeroSectionSubtitle>
          <LinksSection>
            <NavButtonBlue
              href={MUSEUM_URL}
              noMargin
              big
            >{`View Map`}</NavButtonBlue>
            <Space />
            <NavButton
              href={DISCORD_URL}
              noMargin
              big
            >{`Join Discord`}</NavButton>
          </LinksSection>
        </HeroSectionText>
        <SkullVideo autoPlay loop muted playsInline id="skol-video">
          <source src={GLASS_SKOL_URL} type="video/mp4" />
        </SkullVideo>
      </HeroSectionContent>
    </HeroSection>
  );

  const renderMintSection: JSX.Element = (
    <MintSectionContent>
      <MintItem>
        <MintItemTitle>{`Mint Your NFT`}</MintItemTitle>
        <Card skolId={3825} />
        <MintItemText>{`Mint a unique 3D Solana generated NFT, the famous Sp00ky Sk0L.`}</MintItemText>
      </MintItem>
      <MintItem>
        <MintItemTitle>{`Own Your Land`}</MintItemTitle>
        <MintItemSvg src={getHomepageImageByName("rooms.svg")} alt={`Map`} />
        <MintItemText2>{`Buying an NFT means you own land in the Sp00kyVerse Museum.`}</MintItemText2>
      </MintItem>
    </MintSectionContent>
  );

  const renderWhatSection: JSX.Element = (
    <WhatSection>
      <WhatSectionContent>
        <SectionTitle>What is Sp00ky Sk0Ls?</SectionTitle>
        <WhatSectionSubtitle>
          {`We are a collection of 1800 sp00ky 3D critters with one mission: to sp00k the blockchain. `}
        </WhatSectionSubtitle>
        <WhatSectionSubtitle>
          {`Minting a Sp00ky Sk0L grants you access to the most prestigious metaverse ever built, the Sp00kyVerse. 
          We have made it our goal to ensure utility is always offered to our Sk0Ls, 
          and we will expand this over time as we continue to build and deliver value to our unique community.`}
        </WhatSectionSubtitle>
      </WhatSectionContent>
      {renderMintSection}
    </WhatSection>
  );

  const renderArtSection: JSX.Element = (
    <WhatSection>
      <WhatSectionContent>
        <SectionTitle>Our Art</SectionTitle>
        <ArtGrid>
          {selectedSkols.map((id: number) => (
            <SkolArt src={getMapSkolImageDataByIdUrl(id)} alt={`Skol #${id}`} />
          ))}
        </ArtGrid>
      </WhatSectionContent>
    </WhatSection>
  );

  const renderJoinDiscord = (
    <>
      <HalfSpacer />
      <FeaturedSection>
        <NavButtonBlue
          href={MUSEUM_URL}
          big
          mobile
          noMargin
        >{`View Map`}</NavButtonBlue>
        <Space mobile />
        <NavButton
          href={DISCORD_URL}
          big
          mobile
          noMargin
        >{`Join Discord`}</NavButton>
        <HalfSpacerMobile />
        <SectionTitle>Featured On:</SectionTitle>
        <FeaturedBox>
          NFTJet.com
          <FeaturedImg src={NFTJetLogo} alt="NFT Jet Logo" />
        </FeaturedBox>
      </FeaturedSection>
    </>
  );

  return (
    <main>
      {renderHeroSection}
      {renderJoinDiscord}
      <HalfSpacer />
      <HalfSpacerMobile />
      <MapInline />
      <Spacer />
      {renderWhatSection}
      {renderArtSection}
      <Spacer />
      <Roadmap />
      <FaqSection />
    </main>
  );
};

export default HomePage;
