import React from "react";
import { MapElements } from "../Map";
import { initialTranslate, RoomClickbox, RoomView, Translate } from "./data";
import {
  Skol,
  generateData,
  getRoomData,
  generateRoomClickBoxes,
  generateInitialTranslate,
  getRoomClickbox,
  generateRoomViews,
  getSkolTranslate,
  getRoomIdFromSkolId,
} from "./generate-data";

type useMuseumProps = {
  width: number;
  height: number;
  mapElements: MapElements | undefined;
  skolUrl: string;
  updateSkolUrl: (id: number) => void;
  clearSkolUrl: () => void;
};

interface UseMuseumHook {
  defaultTranslate: Translate;
  translate: Translate;
  data: Skol[];
  visibleData: Skol[];
  roomClickboxes: RoomClickbox[];
  roomId: string | undefined;
  selectedRoomClickbox: RoomClickbox | undefined;
  roomViews: RoomView[];
  updateRoom: (newRoomId: string) => void;
  clearRoom: () => void;
  skolId: number | undefined;
  updateSkol: (id: number) => void;
  clearSkol: () => void;
}

const useMuseum = (props: useMuseumProps): UseMuseumHook => {
  const { width, skolUrl, updateSkolUrl, clearSkolUrl } = props;

  const [data, setData] = React.useState<Skol[]>([]);
  const [visibleData, setVisibleData] = React.useState<Skol[]>([]);
  const [roomId, setRoomId] = React.useState<string | undefined>(undefined);
  const [skolId, setSkolId] = React.useState<number | undefined>(undefined);
  const [defaultTranslate, setDefaultTranslate] =
    React.useState<Translate>(initialTranslate);
  const [translate, setTranslate] = React.useState<Translate>(initialTranslate);
  const [roomClickboxes, setRoomClickboxes] = React.useState<RoomClickbox[]>(
    []
  );
  const [roomViews, setRoomViews] = React.useState<RoomView[]>([]);
  const [selectedRoomClickbox, setSelectedRoomClickbox] = React.useState<
    RoomClickbox | undefined
  >(undefined);

  React.useEffect(() => {
    // If not already equal
    const skolUrlNum: number = parseInt(skolUrl);
    if ("" !== skolUrl && skolUrlNum !== skolId) {
      setSkolId(skolUrlNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skolUrl]);

  // Generate Data based on width
  React.useEffect(() => {
    const newData: Skol[] = generateData(width);
    setData(newData);
    const newRoomClickboxes: RoomClickbox[] = generateRoomClickBoxes(width);
    setRoomClickboxes(newRoomClickboxes);
    const newRoomViews: RoomView[] = generateRoomViews(width);
    setRoomViews(newRoomViews);
    const newInitialTranslate: Translate = generateInitialTranslate(width);
    setTranslate(newInitialTranslate);
    setDefaultTranslate(newInitialTranslate);
  }, [width]);

  // Select Visible Data based on room Id
  React.useEffect(() => {
    const newVisibleData: Skol[] = getRoomData(data, roomId);
    setVisibleData(newVisibleData);

    const newSelectedRoomClickbox: RoomClickbox | undefined = getRoomClickbox(
      roomClickboxes,
      roomId
    );

    setSelectedRoomClickbox(newSelectedRoomClickbox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, roomId]);

  // Set new translate when skol, room changes
  // First check for skol, if so, set translate and then set the room accordingly
  // Else check for the room, if so, set translate
  // Else set translate to the default
  React.useEffect(() => {
    if (skolId !== undefined) {
      // get the translate of the skol and call setTranslate

      const newRoomId: string | undefined = getRoomIdFromSkolId(skolId);

      setRoomId(newRoomId);

      const newTranslate: Translate = getSkolTranslate(data, skolId);

      setTranslate(newTranslate);
    } else if (roomId !== undefined) {
      // get the translate of the room and call setTranslate

      const newRoomClickbox: RoomClickbox | undefined = getRoomClickbox(
        roomClickboxes,
        roomId
      );

      if (!newRoomClickbox) {
        return;
      }

      setTranslate(newRoomClickbox.translate);
    } else {
      setTranslate(defaultTranslate);
    }
  }, [defaultTranslate, roomClickboxes, roomId, skolId, data]);

  const updateRoom = (newRoomId: string) => {
    setRoomId(newRoomId);
  };

  const clearRoom = () => {
    setRoomId(undefined);
  };

  const updateSkol = (id: number) => {
    setSkolId(id);
    updateSkolUrl(id);
  };

  const clearSkol = () => {
    clearSkolUrl();
    setSkolId(undefined);
  };

  return {
    defaultTranslate: defaultTranslate,
    translate: translate,
    data: data,
    visibleData: visibleData,
    roomClickboxes: roomClickboxes,
    roomId: roomId,
    selectedRoomClickbox: selectedRoomClickbox,
    roomViews: roomViews,
    updateRoom: updateRoom,
    clearRoom: clearRoom,
    skolId: skolId,
    updateSkol: updateSkol,
    clearSkol: clearSkol,
  };
};

export default useMuseum;
