import styled from "@emotion/styled";

export const DISCORD_URL: string = "https://discord.com/invite/Sp00kySk0Ls";

export const HOMEPAGE_URL: string = "/";
export const MUSEUM_URL: string = "/map";
//export const MINT_URL: string = "https://mint.spookyskols.com/";

interface NavButtonButtonProps {
  alt?: boolean;
  big?: boolean;
  mobile?: boolean;
  noMargin?: boolean;
}

export const NavButton = styled.a`
  text-decoration: none;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props: NavButtonButtonProps) => (props.big ? "25px" : "15px")};
  line-height: ${(props: NavButtonButtonProps) =>
    props.big ? "24px" : "18px"};
  background-color: ${(props: NavButtonButtonProps) =>
    props.alt ? "white" : "#7d1773"};
  color: ${(props: NavButtonButtonProps) => (props.alt ? "#7d1773" : "white")};
  border: none;
  border-radius: 0.2rem;
  margin: ${(props: NavButtonButtonProps) => (props.noMargin ? "0" : "0 1rem")};
  padding: ${(props: NavButtonButtonProps) =>
    props.big ? "0.8rem 1.2rem" : "0.5rem 1rem"};

  @media (min-width: 800px) {
    display: ${(props: NavButtonButtonProps) =>
      props.big && props.mobile ? "none" : ""};
  }
  @media (max-width: 799px) {
    display: ${(props: NavButtonButtonProps) =>
      props.big && !props.mobile ? "none" : ""};
    font-size: ${(props: NavButtonButtonProps) =>
      props.big ? "16px" : "15px"};
    line-height: ${(props: NavButtonButtonProps) =>
      props.big ? "19px" : "18px"};
  }

  @media (max-width: 599px) {
    font-size: ${(props: NavButtonButtonProps) =>
      props.big ? "12px" : "10px"};
    line-height: ${(props: NavButtonButtonProps) =>
      props.big ? "14px" : "12px"};
    margin: 0 0.4rem;
  }
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const NavButtonBlue = styled(NavButton)`
  background-color: ${(props: NavButtonButtonProps) =>
    props.alt ? "white" : "#5165f6"};
  color: ${(props: NavButtonButtonProps) => (props.alt ? "#5165f6" : "white")};
`;
