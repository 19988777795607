import React from "react";

import styled from "@emotion/styled";

import LoaderGif from "../assets/loader/loader.gif";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

const StyledLoader = styled.img`
  width: 6rem;
  height: 6rem;
  opacity: 0.4;
`;

const Loader = (): JSX.Element => {
  return (
    <Container>
      <StyledLoader src={LoaderGif} alt="Loader" />
    </Container>
  );
};

export default Loader;
