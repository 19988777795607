import { range } from "d3";
import { getMapRoomViewUrl } from "../../s3-resources/paths";
import {
  initialTranslate,
  Room,
  RoomClickbox,
  rooms,
  RoomView,
  Translate,
} from "./data";

import raritydata from "../data/rarity-data.json";

export type Skol = {
  x: number;
  y: number;
  radius: number;
  id: number;
  color: string;
};

const BASE_WIDTH = 1200;

export const getSkolData = (data: Skol[], skolId: number): Skol => {
  return data[skolId];
};

export const getSkolTranslate = (data: Skol[], skolId: number): Translate => {
  const skol: Skol = data[skolId];

  const newTranslate: Translate = {
    x: -(skol.x + skol.radius / 2),
    y: -(skol.y + skol.radius / 2),
    scale: 6,
  };
  return newTranslate;
};

export const getRoomIdFromSkolId = (id: number): string => {
  if (id < 750) {
    return "red";
  } else if (id < 1500) {
    return "blue";
  } else if (id < 2250) {
    return "green";
  } else if (id < 2750) {
    return "leafy";
  } else if (id < 3250) {
    return "dark";
  } else if (id < 3750) {
    return "lava";
  } else if (id < 4250) {
    return "soft";
  } else if (id < 4750) {
    return "ice";
  } else if (id < 4950) {
    return "sunset";
  } else {
    return "spooky";
  }
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRoom = (roomId: string | undefined): Room | undefined => {
  const newRoom: Room | undefined = rooms.find(
    (room: Room) => roomId === room.id
  );
  return newRoom;
};

export const getRoomData = (data: Skol[], roomId: string | undefined) => {
  const room: Room | undefined = getRoom(roomId);
  if (!room) {
    return [];
  }

  const start = room.indexOffset;
  const end = room.indexOffset + room.rowLen * room.columnLen;

  return data.slice(start, end);
};

export const getRoomClickbox = (
  roomClickboxes: RoomClickbox[],
  roomId: string | undefined
) => {
  const roomClickbox: RoomClickbox | undefined = roomClickboxes.find(
    (r: RoomClickbox) => roomId === r.id
  );

  return roomClickbox;
};

const getColorByRarity = (rarity: string): string => {
  switch (rarity) {
    case "common":
      return "#666666bb";
    case "rare":
      return "#999999cc";
    case "super-rare":
      return "#bbbbbbdd";
    case "god":
      return "#ddddddee";
    case "spooky":
      return "#a22ef4ff";
    default:
      return "#666666bb";
  }
};

export const generateData = (width: number): Skol[] => {
  const widthAdjusted = (value: number) => {
    return (value * width) / BASE_WIDTH;
  };

  const size = widthAdjusted(6);
  const radius = widthAdjusted(6);

  const generateRoomData = (room: Room): Skol[] => {
    const rowLen = room.rowLen;
    const columnLen = room.columnLen;
    const xOffset = room.xOffset;
    const yOffset = room.yOffset;
    const indexOffset = room.indexOffset;

    const xPadding = widthAdjusted(room.xPadding);
    const yPadding = widthAdjusted(room.yPadding);

    return range(rowLen * columnLen).map((d, index) => {
      const id = indexOffset + index;
      const rarity = raritydata[id];
      const color = getColorByRarity(rarity);

      return {
        x: widthAdjusted(xOffset) + xPadding + (d % rowLen) * (size + xPadding),
        y: widthAdjusted(yOffset) + Math.floor(d / rowLen) * (size + yPadding),
        radius: radius,
        id: id,
        color: color,
      };
    });
  };

  const data: Skol[] = rooms
    .map((room: Room) => generateRoomData(room))
    .reduce((prev, next) => prev.concat(next));

  return data;
};

export const generateRoomClickBoxes = (width: number): RoomClickbox[] => {
  const widthAdjusted = (value: number) => {
    return (value * width) / BASE_WIDTH;
  };

  const generateRoomClickBox = (room: Room): RoomClickbox => {
    const clickBox: RoomClickbox = room.clickBox;

    return {
      id: clickBox.id,
      x: widthAdjusted(clickBox.x),
      y: widthAdjusted(clickBox.y),
      width: widthAdjusted(clickBox.width),
      height: widthAdjusted(clickBox.height),
      translate: {
        x: widthAdjusted(clickBox.translate.x),
        y: widthAdjusted(clickBox.translate.y),
        scale: clickBox.translate.scale,
      },
    };
  };

  const data: RoomClickbox[] = rooms.map((room: Room) =>
    generateRoomClickBox(room)
  );

  return data;
};

export const generateInitialTranslate = (width: number): Translate => {
  const widthAdjusted = (value: number) => {
    return (value * width) / BASE_WIDTH;
  };

  return {
    x: widthAdjusted(initialTranslate.x),
    y: widthAdjusted(initialTranslate.y),
    scale: initialTranslate.scale,
  };
};

export const generateRoomViews = (width: number): RoomView[] => {
  const widthAdjusted = (value: number) => {
    return (value * width) / BASE_WIDTH;
  };

  const generateRoomView = (room: Room): RoomView => {
    const view: RoomView = room.view;

    return {
      id: view.id,
      url: getMapRoomViewUrl(view.id),
      x: widthAdjusted(view.x),
      y: widthAdjusted(view.y),
      width: widthAdjusted(view.width),
    };
  };

  const data: RoomView[] = rooms.map((room: Room) => generateRoomView(room));

  return data;
};
