import React from "react";

import styled from "@emotion/styled";
import { AppBar } from "@mui/material";

import { DISCORD_URL, MUSEUM_URL, NavButton, NavButtonBlue } from "./utils";
import Logo from "./Logo";
import { MAX_CONTENT_WIDTH } from "../utils/universals";

const AppBarStyled = styled(AppBar)`
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AppBarContent = styled.div`
  width: ${MAX_CONTENT_WIDTH}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface LinkProps {
  alt?: boolean;
}

export const SectionLink = styled.a`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 0 1rem;
  color: ${(props: LinkProps) => (props.alt ? "black" : "white")};
  text-decoration: none;
  @media (max-width: 599px) {
    display: none;
  }
`;

const AppBarBlock = styled.div`
  width: 100%;
  height: 50px;
  background-color: #303030;
`;

const Header = (): JSX.Element => {
  return (
    <React.Fragment>
      <AppBarBlock />
      <AppBarStyled>
        <AppBarContent>
          <Logo />
          <NavButtons>
            <NavButtonBlue href={MUSEUM_URL}>{`Map`}</NavButtonBlue>
            <NavButton href={DISCORD_URL}>{`Discord`}</NavButton>
          </NavButtons>
        </AppBarContent>
      </AppBarStyled>
    </React.Fragment>
  );
};

export default Header;
