import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";

import HomePage from "./homepage/HomePage";
import MapPage from "./map/MapPage";
import Header from "./nav/Header";
import Footer from "./nav/Footer";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/map" element={<MapPage />} />
        <Route path="/museum" element={<Navigate to="/map" />} />
        <Route path="/lava-room" element={<Navigate to="/map" />} />
        <Route path="/dark-room" element={<Navigate to="/map" />} />
        <Route path="/red-room" element={<Navigate to="/map" />} />
        <Route path="/blue-room" element={<Navigate to="/map" />} />
        <Route path="/green-room" element={<Navigate to="/map" />} />
        <Route path="/ice-room" element={<Navigate to="/map" />} />
        <Route path="/leafy-room" element={<Navigate to="/map" />} />
        <Route path="/soft-room" element={<Navigate to="/map" />} />
        <Route path="/sunset-room" element={<Navigate to="/map" />} />
        <Route path="/spooky-room" element={<Navigate to="/map" />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
