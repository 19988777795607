import React from "react";

import renderMap from "./d3/renderMap.js";

import styled from "@emotion/styled";
import useMuseum from "./data/useMuseum";
import CardModal from "./card/CardModal";

export const MAP_WIDTH = 1200;
export const MAP_HEIGHT = 1320;

type FrameProps = {
  width: number;
  height: number;
};

const Frame = styled.div`
  position: absolute;
  width: ${(props: FrameProps) => props.width}px;
  height: ${(props: FrameProps) => props.height}px;

  margin: 10px 0 20px 0;
`;

const MapContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MapTitle = styled.h1`
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  margin: 60px 0px 20px 0px;
  @media (max-width: 1199px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 1rem 0;
  }
`;

const MapSubtitle = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  max-width: 100%;

  text-align: center;
  margin: 0px 0px 20px 0px;
  color: #ffffff;

  @media (max-width: 799px) {
    font-size: 16px;
    line-height: 18px;
    max-width: 95%;
  }
`;

const MapAction = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  width: 200px;
  padding: 6px 0px;
  max-width: 60%;

  border: 2px solid #7d1773aa;

  text-align: center;
  margin: 0px 0px 0px 0px;
  color: #ffffffdd;

  @media (max-width: 999px) {
    font-size: 14px;
    line-height: 18px;
    margin: 0px 0px 8px 0px;
  }
`;

export type MapElements = {
  svg: any;
  zoomd3: any;
};

export type MapProps = {
  width: number;
  height: number;
  skolUrl: string;
  updateSkolUrl: (id: number) => void;
  clearSkolUrl: () => void;
};

const Map = (props: MapProps): JSX.Element => {
  const { width, height, skolUrl, updateSkolUrl, clearSkolUrl } = props;

  const [mapElements, setMapElements] = React.useState<MapElements | undefined>(
    undefined
  );

  const {
    defaultTranslate,
    translate,
    data,
    visibleData,
    roomClickboxes,
    roomId,
    selectedRoomClickbox,
    roomViews,
    updateRoom,
    clearRoom,
    skolId,
    updateSkol,
    clearSkol,
  } = useMuseum({
    width,
    height,
    mapElements,
    skolUrl,
    updateSkolUrl,
    clearSkolUrl,
  });

  const renderCardModal: JSX.Element = React.useMemo(() => {
    if (undefined === skolId || !selectedRoomClickbox || !mapElements) {
      return <></>;
    }

    return (
      <CardModal
        mapElements={mapElements}
        clickbox={selectedRoomClickbox}
        skolId={skolId}
        clearSkol={clearSkol}
        width={width}
        height={height}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skolId, selectedRoomClickbox, mapElements]);

  React.useLayoutEffect(() => {
    if (!data || !roomClickboxes || !roomViews) {
      return;
    }

    const { svg, zoomd3 }: any = renderMap(
      width,
      height,
      defaultTranslate,
      translate,
      data,
      visibleData,
      roomClickboxes,
      roomViews,
      roomId,
      updateRoom,
      clearRoom,
      skolId,
      updateSkol,
      clearSkol
    );

    setMapElements({ svg, zoomd3 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    width,
    height,
    data,
    visibleData,
    roomClickboxes,
    roomViews,
    roomId,
    skolId,
    translate,
  ]);

  return (
    <MapContent>
      <MapTitle>{`The Museum`}</MapTitle>
      <MapSubtitle>{`Our NFTs give you access to digital land in the Sp00kyVerse Museum.`}</MapSubtitle>
      <MapAction>{roomId ? "Select a Sk0L" : "Select a Room"}</MapAction>
      <div style={{ width: width, height: height, position: "relative" }}>
        <Frame id="frame" width={width} height={height} />
        {renderCardModal}
      </div>
    </MapContent>
  );
};

export default Map;
