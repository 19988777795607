import React from "react";

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

export const LogoStyled = styled.div`
  cursor: pointer;
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 1rem 2rem;
  @media (max-width: 599px) {
    margin: 1rem 1rem;
    font-size: 14px;
    line-height: 18px;
  }
`;

const Logo = () => {
  const navigate = useNavigate();

  return <LogoStyled onClick={() => navigate("/")}>Sp00ky Sk0Ls</LogoStyled>;
};

export default Logo;
