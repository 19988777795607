export type Translate = {
  x: number;
  y: number;
  scale: number;
};

export type RoomClickbox = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  translate: Translate;
};

export type RoomView = {
  id: string;
  url: string;
  x: number;
  y: number;
  width: number;
};

export type Room = {
  id: string;
  name: string;
  rowLen: number;
  columnLen: number;
  indexOffset: number;
  indexOffsetEnd: number;
  xOffset: number;
  yOffset: number;
  xPadding: number;
  yPadding: number;
  clickBox: RoomClickbox;
  view: RoomView;
};

export const initialTranslate: Translate = {
  x: -600,
  y: -660,
  scale: 1,
};

export const rooms: Room[] = [
  {
    id: "red",
    name: "Red",
    rowLen: 30,
    columnLen: 25,
    indexOffset: 0,
    indexOffsetEnd: 749,
    xOffset: 87,
    yOffset: 808,
    xPadding: 2.5,
    yPadding: 2.4,
    clickBox: {
      id: "red",
      x: 63,
      y: 783,
      width: 305,
      height: 257,
      translate: {
        x: -210,
        y: -964,
        scale: 2.8,
      },
    },
    view: {
      id: "red",
      url: "url",
      x: 159,
      y: 883,
      width: 111,
    },
  },
  {
    id: "blue",
    name: "Blue",
    rowLen: 30,
    columnLen: 25,
    indexOffset: 750,
    indexOffsetEnd: 1499,
    xOffset: 472,
    yOffset: 811,
    xPadding: 2.5,
    yPadding: 2.2,
    clickBox: {
      id: "blue",
      x: 448,
      y: 783,
      width: 305,
      height: 257,
      translate: {
        x: -600,
        y: -964,
        scale: 2.8,
      },
    },
    view: {
      id: "blue",
      url: "url",
      x: 544,
      y: 884,
      width: 111,
    },
  },
  {
    id: "green",
    name: "Green",
    rowLen: 30,
    columnLen: 25,
    indexOffset: 1500,
    indexOffsetEnd: 2249,
    xOffset: 859,
    yOffset: 810,
    xPadding: 2.5,
    yPadding: 2.2,
    clickBox: {
      id: "green",
      x: 834,
      y: 783,
      width: 305,
      height: 257,
      translate: {
        x: -986,
        y: -964,
        scale: 2.8,
      },
    },
    view: {
      id: "green",
      url: "url",
      x: 936,
      y: 884,
      width: 111,
    },
  },
  {
    id: "leafy",
    name: "Leafy",
    rowLen: 25,
    columnLen: 20,
    indexOffset: 2250,
    indexOffsetEnd: 2749,
    xOffset: 860,
    yOffset: 480,
    xPadding: 4,
    yPadding: 4,
    clickBox: {
      id: "leafy",
      x: 834,
      y: 448,
      width: 304,
      height: 256,
      translate: {
        x: -980,
        y: -630,
        scale: 2.8,
      },
    },
    view: {
      id: "leafy",
      url: "url",
      x: 940,
      y: 544,
      width: 94,
    },
  },
  {
    id: "dark",
    name: "Dark",
    rowLen: 25,
    columnLen: 20,
    indexOffset: 2750,
    indexOffsetEnd: 3249,
    xOffset: 90,
    yOffset: 480,
    xPadding: 4,
    yPadding: 4,
    clickBox: {
      id: "dark",
      x: 63,
      y: 448,
      width: 304,
      height: 256,
      translate: {
        x: -210,
        y: -630,
        scale: 2.8,
      },
    },
    view: {
      id: "dark",
      url: "url",
      x: 161,
      y: 544,
      width: 111,
    },
  },
  {
    id: "lava",
    name: "Lava",
    rowLen: 25,
    columnLen: 20,
    indexOffset: 3250,
    indexOffsetEnd: 3749,
    xOffset: 88,
    yOffset: 95,
    xPadding: 4,
    yPadding: 4,
    clickBox: {
      id: "lava",
      x: 63,
      y: 65,
      width: 304,
      height: 256,
      translate: {
        x: -210,
        y: -260,
        scale: 2.8,
      },
    },
    view: {
      id: "lava",
      url: "url",
      x: 159,
      y: 159,
      width: 111,
    },
  },
  {
    id: "soft",
    name: "Soft",
    rowLen: 25,
    columnLen: 20,
    indexOffset: 3750,
    indexOffsetEnd: 4249,
    xOffset: 860,
    yOffset: 92,
    xPadding: 4,
    yPadding: 4,
    clickBox: {
      id: "soft",
      x: 834,
      y: 65,
      width: 305,
      height: 256,
      translate: {
        x: -980,
        y: -260,
        scale: 2.8,
      },
    },
    view: {
      id: "soft",
      url: "url",
      x: 948,
      y: 156,
      width: 76,
    },
  },
  {
    id: "ice",
    name: "Ice",
    rowLen: 25,
    columnLen: 20,
    indexOffset: 4250,
    indexOffsetEnd: 4749,
    xOffset: 480,
    yOffset: 480,
    xPadding: 4,
    yPadding: 4,
    clickBox: {
      id: "ice",
      x: 448,
      y: 448,
      width: 304,
      height: 256,
      translate: {
        x: -600,
        y: -630,
        scale: 2.8,
      },
    },
    view: {
      id: "ice",
      url: "url",
      x: 545,
      y: 545,
      width: 110,
    },
  },
  {
    id: "sunset",
    name: "Sunset",
    rowLen: 20,
    columnLen: 10,
    indexOffset: 4750,
    indexOffsetEnd: 4949,
    xOffset: 474,
    yOffset: 257,
    xPadding: 6.4,
    yPadding: 3,
    clickBox: {
      id: "sunset",
      x: 451,
      y: 234,
      width: 301,
      height: 135,
      translate: {
        x: -600,
        y: -410,
        scale: 3,
      },
    },
    view: {
      id: "sunset",
      url: "url",
      x: 537,
      y: 270,
      width: 121,
    },
  },
  {
    id: "spooky",
    name: "Spooky",
    rowLen: 10,
    columnLen: 5,
    indexOffset: 4950,
    indexOffsetEnd: 4999,
    xOffset: 462,
    yOffset: 88,
    xPadding: 20,
    yPadding: 12,
    clickBox: {
      id: "spooky",
      x: 448,
      y: 64,
      width: 306,
      height: 123,
      translate: {
        x: -600,
        y: -250,
        scale: 3,
      },
    },
    view: {
      id: "spooky",
      url: "url",
      x: 531,
      y: 93,
      width: 134,
    },
  },
];
