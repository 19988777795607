import styled from "@emotion/styled";
import React from "react";

import { BackgroundImage } from "./HomePage";
import { getRoadmapImageByName } from "../s3-resources/paths";

const RoadmapSection = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  padding: 6rem 0;
`;

const RoadmapContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  justify-content: center;
  max-width: 1200px;
`;

const RoadmapHeader = styled.div`
  position: relative;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    height: 4rem;
    margin-bottom: 2rem;
  }
`;

const SectionTitle = styled.h1`
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  margin: 0 0 2rem 0;
  @media (max-width: 1199px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 1rem 0;
  }
`;

const RoadmapHeading = styled(SectionTitle)``;

const RoadmapContent = styled.div`
  width: 100%;
  padding: 0px 0px 60px 0px;
  display: grid;
  place-items: center;

  @media (max-width: 800px) {
    padding: 0px 0px 40px 0px;
  }

  @media (max-width: 600px) {
    padding: 0px 0px 10px 0px;
  }
`;

const RoadmapStyled = styled.img`
  overflow: hidden;
`;

const Roadmapw1200 = styled(RoadmapStyled)`
  width: 1000px;
  height: 500px;
  @media (max-width: 1199px) {
    display: none;
  }
`;

const Roadmapw800 = styled(RoadmapStyled)`
  width: 90%;
  max-width: 1000px;
  min-height: 350px;
  @media (min-width: 1200px) {
    display: none;
  }
  @media (max-width: 799px) {
    display: none;
  }
`;

const Roadmapw600 = styled(RoadmapStyled)`
  width: 500px;
  height: 860px;
  @media (min-width: 800px) {
    display: none;
  }
  @media (max-width: 599px) {
    display: none;
  }
`;

const Roadmapw320 = styled(RoadmapStyled)`
  width: 100%;
  max-width: 360px;
  max-height: 1584px;
  @media (min-width: 600px) {
    display: none;
  }
`;

const BackgroundColor = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -0%,
    rgba(0, 0, 0, 0.91) -0.05%,
    rgba(155, 52, 145, 0.19) 48.44%,
    rgba(44, 15, 41, 0.642501) 84.56%,
    rgba(0, 0, 0, 0.823377) 99%,
    rgba(0, 0, 0, 0.39) 99.01%
  );
`;

const BackgroundColor2 = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0) -0%,
    rgba(0, 0, 0, 0.91) -0.05%,
    rgba(155, 52, 145, 0.19) 48.44%,
    rgba(44, 15, 41, 0.642501) 84.56%,
    rgba(0, 0, 0, 0.823377) 99%,
    rgba(0, 0, 0, 0.39) 99.01%
  );
`;

const Roadmap = (): JSX.Element => {
  const renderRoadmapContent = (
    <RoadmapContent>
      <Roadmapw1200
        src={getRoadmapImageByName(`roadmap-1.svg`)}
        alt="Roadmap"
      />
      <Roadmapw800 src={getRoadmapImageByName(`roadmap-1.svg`)} alt="Roadmap" />
      <Roadmapw600 src={getRoadmapImageByName(`roadmap-2.svg`)} alt="Roadmap" />
      <Roadmapw320 src={getRoadmapImageByName(`roadmap-3.svg`)} alt="Roadmap" />
    </RoadmapContent>
  );

  return (
    <RoadmapSection id="roadmap">
      <BackgroundImage
        src={getRoadmapImageByName(`background.png`)}
        alt="night"
      />
      <BackgroundColor id="background-color" />
      <BackgroundColor2 id="background-color" />
      <RoadmapContainer>
        <RoadmapHeader>
          <RoadmapHeading>{`Our Roadmap`}</RoadmapHeading>
        </RoadmapHeader>
        {renderRoadmapContent}
      </RoadmapContainer>
    </RoadmapSection>
  );
};

export default Roadmap;
