export enum Rarity {
  Common = "common",
  Rare = "rare",
  Super_Rare = "super-rare",
  God = "god",
  Spooky = "spooky",
}

export type SkolAttribute = {
  trait_type: string;
  value: string;
};

const RESOURCES_BASE_URL = "https://spookyskols.com/spooky-resources";

// Map
const MAP_BASE_URL = `${RESOURCES_BASE_URL}/map`;

const MAP_SKOLS_BASE_URL = `${MAP_BASE_URL}/skols`;

const MAP_SKOLS_IMAGES_URL = `${MAP_SKOLS_BASE_URL}/images-smol`;
const MAP_SKOLS_DISPLAYDATA_URL = `${MAP_SKOLS_BASE_URL}/display-data`;
const MAP_SKOLS_RARITY_URL = `${MAP_SKOLS_BASE_URL}/rarity`;

export const MAP_VIEW_URL = `${MAP_BASE_URL}/view`;

export const MAP_CLOSE_URL = `${MAP_BASE_URL}/close.svg`;
export const MAP_EXIT_URL = `${MAP_BASE_URL}/exit.svg`;
export const MAP_MUSEUM_URL = `${MAP_BASE_URL}/museum-map.svg`;

const MAP_CARD_BACKGROUNDS_URL = `${MAP_BASE_URL}/card-backgrounds`;
const MAP_ROOM_BACKGROUNDS_URL = `${MAP_BASE_URL}/room-backgrounds`;

// Homepage
const HOMEPAGE_BASE_URL = `${RESOURCES_BASE_URL}/homepage`;

const ROADMAP_BASE_URL = `${HOMEPAGE_BASE_URL}/roadmap`;

const VIDEO_BASE_URL = `${HOMEPAGE_BASE_URL}/video`;

export const getHomepageImageByName = (name: string) => {
  return `${HOMEPAGE_BASE_URL}/${name}`;
};

export const getRoadmapImageByName = (name: string) => {
  return `${ROADMAP_BASE_URL}/${name}`;
};

export const getVideoByName = (name: string) => {
  return `${VIDEO_BASE_URL}/${name}`;
};

export const getMapSkolImageDataByIdUrl = (id: number) => {
  return `${MAP_SKOLS_IMAGES_URL}/${id}.png`;
};

export const getMapSkolDisplayDataByIdUrl = (id: number) => {
  return `${MAP_SKOLS_DISPLAYDATA_URL}/${id}.json`;
};

export const getCardBackgroundUrlByName = (name: string): string => {
  return `${MAP_CARD_BACKGROUNDS_URL}/${name}.png`;
};

export const getRoomBackgroundUrlByName = (name: string): string => {
  return `${MAP_ROOM_BACKGROUNDS_URL}/${name}.png`;
};

export const getSkolRarityUrlById = (rarity: Rarity | string): string => {
  return `${MAP_SKOLS_RARITY_URL}/${rarity}.svg`;
};

export const getMapRoomViewUrl = (roomId: string): string => {
  return `${MAP_VIEW_URL}/view-${roomId}.svg`;
};

export type SkolDisplayData = {
  name: string;
  attributes: SkolAttribute[];
  rarity: Rarity;
  ["rarity-rank"]: number;
};

export const fetchMapSkolDisplayDataById = async (
  id: number
): Promise<SkolDisplayData | undefined> => {
  return await fetch(getMapSkolDisplayDataByIdUrl(id))
    .then(async (res) => {
      const displaydata: SkolDisplayData = await res.json();

      return displaydata;
    })
    .catch((error) => {
      return undefined;
    });
};
