import styled from "@emotion/styled";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MAX_CONTENT_WIDTH } from "../utils/universals";

const FaqSectionStyled = styled.div`
  position: relative;
  background-color: transparent;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FaqSectionContent = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  padding: 4rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const SectionTitle = styled.h1`
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  color: white;
  margin: 0 0 2rem 0;
  @media (max-width: 1199px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 1rem 0;
  }
`;
const FaqsContent = styled.div`
  max-width: 80rem;
  padding: 2rem;
`;

const FaqTitle = styled(Typography)`
  color: white;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 31px;
  @media (max-width: 799px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const FaqContent = styled(Typography)`
  color: white;
  margin-bottom: 0.4rem;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 799px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

type Faq = {
  id: string;
  title: string;
  content: string[];
};

const faqs: Faq[] = [
  {
    id: "spooky",
    title: `What is Sp00ky Sk0Ls?`,
    content: [
      `Sp00ky Sk0Ls is a metaverse project featuring a collection of 1800 unique 3D generated Sp00ky Sk0L NFTS, exclusive to the Solana blockchain.`,
    ],
  },
  {
    id: "mint-price",
    title: `What is the mint price?`,
    content: [`0.69 SOL (nice).`],
  },
  {
    id: "mint-data",
    title: `When is the mint date?`,
    content: [`TBD`],
  },
  {
    id: "utility",
    title: `What is the utility?`,
    content: [
      `Passive income - every marketplace sale will be subject to a 5% tax back to holders. Hold more Sp00ky Sk0Ls and receive more passive income from each sale!
    `,
      `Metaverse - The Sp00kyVerse will provide users with digital real estate to express themselves, share messages with others, and market their own projects (think of a billboard in cyberspace).
    `,
      `Deflationary - Project funds and commissions will go towards buying back and burning Sp00ky Sk0Ls on the secondary marketplace, thereby simultaneously increasing the floor price and reducing the supply. This is a recipe for an increasing value of your digital assets.`,
    ],
  },
  {
    id: "Sp00kyVerse",
    title: "What is the Sp00kyVerse?",
    content: [
      `The Sp00kyVerse is a metaverse environment reminiscent of a classical museum. Each Sp00ky Sk0L will have its own room within the museum in which the Sp00ky Sk0L is displayed. The holder may decorate the room and various plaques to express themselves, share messages, and even market their own projects/artwork.`,
      `Additionally, all holders of 5+ Sp00ky Sk0Ls will have part ownership through the Sp00ky Sk0Ls DAO (Decentralized Autonomous Organization). The future development of the Sp00kyVerse post-launch will be directed by the DAO. More details will be released closer to launch.`,
    ],
  },
  {
    id: "buy",
    title: `How do I mint/buy?`,
    content: [
      `We will post the link to the official mint page on our official Twitter account and in #announcements in our official discord server.`,
      ` WE WILL ONLY POST THE MINT LINK HERE. DO NOT TRUST ANY DIRECT MESSAGES OR LINKS IN GENERAL from other users. The official mint page will have a prompt directing you to connect your wallet and then a simple Mint button.`,
    ],
  },
  {
    id: "sell",
    title: `Where can I sell my Sp00ky Sk0Ls?`,
    content: [
      `Following mint,  the Sp00ky Sk0Ls collection will be live on secondary marketplaces (such as Solanart.io, Digital Eyes, and Magic Eden).`,
    ],
  },
];
const FaqSection = (): JSX.Element => {
  return (
    <FaqSectionStyled id="faq">
      <FaqSectionContent>
        <SectionTitle>FAQs</SectionTitle>
        <FaqsContent>
          {faqs.map((faq: Faq) => (
            <Accordion
              sx={{
                width: `100%`,
                border: `1px solid white`,
                backgroundColor: `transparent`,
                marginBottom: `10px`,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${faq.id}-content`}
                id={`${faq.id}-header`}
              >
                <FaqTitle>{faq.title}</FaqTitle>
              </AccordionSummary>
              <AccordionDetails>
                {faq.content.map((line: string) => (
                  <FaqContent>{line}</FaqContent>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </FaqsContent>
      </FaqSectionContent>
    </FaqSectionStyled>
  );
};

export default FaqSection;
